<template>
  <BContainer class="mt-5 text-center">
    <SOLogo class="logo" as-dark />
    <h1 class="title">{{ $t('vw.magic.title') }}</h1>
    <BSpinner v-if="!message" />
    <p v-else class="text-muted">{{ message }}</p>
  </BContainer>
</template>

<script>
import { BContainer, BSpinner } from 'bootstrap-vue';
import { delay } from 'library/src/utilities/delay';
import SOLogo from 'library/components/src/components/so/logo';
import get from 'lodash.get';

const prefix = 'vw.magic';

export default {
  components: { BContainer, BSpinner, SOLogo },
  data() {
    return {
      message: null,
      isError: false,
    };
  },
  computed: {
    prefix: () => prefix,
    token() {
      return this.$route.params.token;
    },
    type() {
      return this.$route.params.type;
    },
  },
  async mounted() {
    try {
      const { type, token } = this;
      let responseData = null;

      switch (type) {
        case 'account':
          responseData = await this.$api.auth.magic(token);
          break;
        case 'reservation':
          responseData = await this.$api.reservation.magic(token);
          break;
      }

      if (!responseData) {
        throw new Error('Invalid responseData of magic link');
      }

      // refresh user data
      if (responseData.token) {
        await this.$store.dispatch('auth/replaceToken', responseData.token); // change token
        await this.$store.dispatch('auth/authentificate'); // update user data

        // delay for authentification
        await delay(500);
      }

      // show message
      if (responseData.message) {
        // try to translate message
        this.message = this.getMessage(responseData);
      }

      // redirect to uri
      if (responseData.redirect) {
        this.$router.replace(responseData.redirect);
      }
    } catch (e) {
      this.isError = true;
      this.message = this.getMessage(e);
    }
  },
  methods: {
    /**
     * @param {Object} data
     * @return {String}
     */
    getMessage(data) {
      const { type } = this;
      let message;

      if (data instanceof Error) {
        message = get(data, 'response.data.message');
      } else {
        message = get(data, 'message');
      }

      // try to translate
      const key = `${prefix}.messages.${type}.${message}`;
      const value = this.$t(key);

      // check if can be translate otherwise return raw message
      return value === key ? message : value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../component';

.title {
  margin-top: $space-default;
}
</style>
